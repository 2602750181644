import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { MobileRule } from "../constants"
import check from "../assets/check_mark.svg"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Img from "gatsby-image"
/** @jsx jsx */
import { jsx } from "@emotion/core"
import SEO from "./seo"
// const isAbout = 

const Header = (props) => {
  const { isAbout, siteTitle } = props;
  const data = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "header_bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      about: file(relativePath: { eq: "about_bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)
  return (
  <header
    css={{
      paddingTop: '89px',
      display: "flex",
      flexDirection: "column",
      position: "relative",
      overflow: "hidden",
      [MobileRule]: {
        paddingTop: '40px',
        marginTop: isAbout ? '80px' : '0',
        borderBottom: "none",
      },
      
    }}
  >
    <SEO title={siteTitle} keywords={['Redovisning', 'Löneadministration', 'Rådgivning']} />
    <div
      css={{
        position: "relative",
        margin: `0 auto`,
        textAlign: "center",
        padding: isAbout ? "12.75rem 1.5rem 12.75rem 1.5rem" : "7.5rem 1.5rem 18rem 1.5rem",
        [MobileRule]: {
          padding: "5rem 2rem 3rem 2rem",
        },
      }}
    >
      <h1
        css={{
          margin: "0 0 2rem 0",
          color: "#FF8479",
          fontWeight: 700,
          fontSize: "4rem",
          lineHeight: "70px",
          maxWidth: isAbout ? '800px' : '600px',
          textAlign: "center",
          letterSpacing: "0.01em",
          [MobileRule]: {
            fontSize: "20px",
            lineHeight: "26px",
          },
        }}
      >
        {isAbout ? 'Clearly – En Stockholmsbaserad redovisningsbyrå' : 'Clearly förenklar ert företagande'}
      </h1>
      {!isAbout && (<div><AnchorLink
        href="#kontakt"
        offset={90}
        css={{
          padding: "0.75rem 2rem",
          textDecoration: "none",
          backgroundColor: "#323943",
          color: "white",
          fontSize: "1rem",
          cursor: "pointer",
          borderRadius: "25px",
          fontWeight: '600'
        }}
      >
        Kontakta oss
      </AnchorLink>
      <div  css={{
        ["p"]: {whiteSpace: 'nowrap'},
        marginTop: '2rem',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          [MobileRule]: {marginTop: '1rem', flexDirection: 'column', ["p"]: {marginBottom: 0}}
        }}>
      <p
      >
        <img alt="checkmark" css={{ marginRight: "0.25rem" }} src={check} />
        Erfarna rådgivare
        </p>
        <p
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img alt="checkmark" css={{ marginRight: "0.25rem", marginLeft: "1rem", [MobileRule]: {marginLeft: 0} }} src={check} />
        Effektiva processer
        </p>
        <p
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img alt="checkmark" css={{ marginRight: "0.25rem", marginLeft: "1rem", [MobileRule]: {marginLeft: 0} }} src={check} />
        Personligt engagemang
      </p>
      </div></div>)
      }
    </div>
    <Img css={{position: 'absolute!important', top: 0, bottom: 0, left: 0, right: 0, zIndex: -1}} imgStyle={{ objectPosition: isAbout ? "top center" : "center center" }} fluid={isAbout ? data.about.childImageSharp.fluid : data.home.childImageSharp.fluid} />
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
